.more {
  position: relative;
}

@media screen and (max-width: 768px) {
  .more {
    width: 334px;
    height: 363px;
    margin: 0 auto 142px auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .more {
    width: 700px;
    height: 245px;
    margin: 0 auto 210px auto;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .more {
    width: 100%;
    height: 433px;
    margin: 0 93px 400px 73px;
  }
}

@media screen and (min-width: 1440px) {
  .more {
    width: 100%;
    height: 433px;
    margin: 0 93px 400px 73px;
  }
}

.more__title {
  position: absolute;
  font-family: Audrey, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

@media screen and (max-width: 768px) {
  .more__title {
    font-size: 90px;
    line-height: 108px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .more__title {
    font-size: 102px;
    line-height: 122px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .more__title {
    font-size: 164px;
    line-height: 197px;
  }
}

@media screen and (min-width: 1440px) {
  .more__title {
    font-size: 164px;
    line-height: 197px;
  }
}

/*.more__title_number_1 {
  top: 0;
  left: 0;
}*/

@media screen and (max-width: 768px) {
  .more__title_number_1 {
    top: 0;
    right: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .more__title_number_1 {
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .more__title_number_1 {
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: 1440px) {
  .more__title_number_1 {
    top: 0;
    left: 0;
  }
}

/*.more__title_number_2 {

}*/

@media screen and (max-width: 768px) {
  .more__title_number_2 {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .more__title_number_2 {
    top: 58px;
    left: 370px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .more__title_number_2 {
    top: 142px;
    left: 594px;
  }
}

@media screen and (min-width: 1440px) {
  .more__title_number_2 {
    top: 91px;
    left: 594px;
  }
}

@keyframes more-title {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(100%, 0);
  }
  51% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.more__title-overlay {
  position: absolute;
  overflow: hidden;
  transition: 1s;
}

@media screen and (max-width: 768px) {
  .more__title-overlay {
    top: 78px;
    right: -10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .more__title-overlay {
    top: 142px;
    left: 370px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .more__title-overlay {
    top: 233px;
    left: 599px;
  }
}

@media screen and (min-width: 1440px) {
  .more__title-overlay {
    top: 233px;
    left: 599px;
  }
}

.more__title_number_3 {
  position: relative;
  color: #B68F33;
}

.more__image {
  position: absolute;
  animation: logo-background 180s infinite;
  transition: 0.5s;
}

@media screen and (max-width: 768px) {
  .more__image {
    width: 155px;
    top: 208px;
    right: 202px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .more__image {
    width: 160px;
    top: 91px;
    left: 210px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .more__image {
    width: 240px;
    top: 145px;
    left: 361px;
  }
}

@media screen and (min-width: 1440px) {
  .more__image {
    width: 240px;
    top: 145px;
    left: 361px;
  }
}

.more__image:hover {
  opacity: 0.8;
}

.more__line {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  transform: rotate(-54deg);
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .more__line {
    width: 120px;
    height: 3px;
    top: 124px;
    right: 90px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .more__line {
    width: 150px;
    height: 4px;
    top: 193px;
    left: 555px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .more__line {
    width: 278px;
    height: 6px;
    top: 330px;
    left: 870px;
  }
}

@media screen and (min-width: 1440px) {
  .more__line {
    width: 278px;
    height: 6px;
    top: 330px;
    left: 870px;
  }
}