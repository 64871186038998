.about-client {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.about-client__column {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
}

@media screen and (max-width: 768px) {
  .about-client__column {
    width: calc(100% - 24px);
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content;
    row-gap: 36px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .about-client__column {
    width: calc(100% - 68px);
    max-width: 840px;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content;
    row-gap: 50px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .about-client__column {
    width: 1106px;
    height: 558px;
    grid-template-columns: 402px 1fr;
    grid-template-rows: min-content 1fr;
    column-gap: 51px;
    row-gap: 45px;
  }
}

@media screen and (min-width: 1440px) {
  .about-client__column {
    width: 1106px;
    height: 558px;
    grid-template-columns: 402px 1fr;
    grid-template-rows: min-content 1fr;
    column-gap: 51px;
    row-gap: 45px;
  }
}

.about-client__texts-header {

}

@media screen and (max-width: 768px) {
  .about-client__texts-header {
    grid-area: 1 / 1 / 2 / 2;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .about-client__texts-header {
    grid-area: 1 / 1 / 2 / 2;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .about-client__texts-header {
    grid-area: 1 / 2 / 2 / 3;
  }
}

@media screen and (min-width: 1440px) {
  .about-client__texts-header {
    grid-area: 1 / 2 / 2 / 3;
  }
}

.about-client__texts-title {
  font-family: Audrey, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  color: #FFFFFF;
}

@media screen and (max-width: 768px) {
  .about-client__texts-title {
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .about-client__texts-title {
    font-size: 36px;
    line-height: 43px;
    text-align: left;
    margin-bottom: 7px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .about-client__texts-title {
    font-size: 36px;
    line-height: 43px;
    text-align: right;
  }
}

@media screen and (min-width: 1440px) {
  .about-client__texts-title {
    font-size: 36px;
    line-height: 43px;
    text-align: right;
  }
}

.about-client__texts-subtitle {
  font-family: Audrey, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  /*margin-bottom: 45px;*/
}

@media screen and (max-width: 768px) {
  .about-client__texts-subtitle {
    font-size: 16px;
    line-height: 19px;
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .about-client__texts-subtitle {
    font-size: 24px;
    line-height: 29px;
    text-align: left;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .about-client__texts-subtitle {
    font-size: 24px;
    line-height: 29px;
    text-align: right;
  }
}

@media screen and (min-width: 1440px) {
  .about-client__texts-subtitle {
    font-size: 24px;
    line-height: 29px;
    text-align: right;
  }
}

.about-client__images {
  display: grid;
}

@media screen and (max-width: 768px) {
  .about-client__images {
    grid-area: 2 / 1 / 3 / 2;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    column-gap: 6px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .about-client__images {
    grid-area: 2 / 1 / 3 / 2;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .about-client__images {
    grid-area: 1 / 1 / 3 / 2;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 24px;
  }
}

@media screen and (min-width: 1440px) {
  .about-client__images {
    grid-area: 1 / 1 / 3 / 2;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 24px;
  }
}

.about-client__image {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .about-client__image {
    object-fit: contain;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .about-client__image {
    object-fit: contain;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .about-client__image {
    object-fit: cover;
  }
}

@media screen and (min-width: 1440px) {
  .about-client__image {
    object-fit: cover;
  }
}

.about-client__image_1 {
  /*grid-area: 1 / 1 / 2 / 2;*/
}

.about-client__image_2 {
  /*grid-area: 2 / 1 / 3 / 2;*/
}

.about-client__texts {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .about-client__texts {
    grid-area: 3 / 1 / 4 / 2;
    align-items: flex-start;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .about-client__texts {
    grid-area: 3 / 1 / 4 / 2;
    align-items: flex-start;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .about-client__texts {
    grid-area: 2 / 2 / 3 / 3;
    align-items: flex-end;
  }
}

@media screen and (min-width: 1440px) {
  .about-client__texts {
    grid-area: 2 / 2 / 3 / 3;
    align-items: flex-end;
  }
}

.about-client__texts-heading {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  color: #B68F33;
}

@media screen and (max-width: 768px) {
  .about-client__texts-heading {
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    margin-bottom: 6px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .about-client__texts-heading {
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .about-client__texts-heading {
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 1440px) {
  .about-client__texts-heading {
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    margin-bottom: 12px;
  }
}

.about-client__texts-text {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #FFFFFF;
}

@media screen and (max-width: 768px) {
  .about-client__texts-text {
    font-size: 9px;
    line-height: 11px;
    text-align: left;
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .about-client__texts-text {
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .about-client__texts-text {
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1440px) {
  .about-client__texts-text {
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    margin-bottom: 25px;
  }
}