* {
  cursor: url(../../images/cursor.png), auto;
  /*cursor: none;*/
}

.body_stop {
  height: 100%;
  overflow-y: hidden;
}

.app {
  background-color: black;

  overflow: hidden;
  scroll-behavior: smooth;
}

.app__column {
  margin: auto;
  position: relative;
  transition: 1s;
  z-index: 0;
  min-width: 320px;
}

@media screen and (max-width: 768px) {
  .app__column {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .app__column {
    width: calc(100% - 72px);
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .app__column {
    width: 1280px;
  }
}

@media screen and (min-width: 1440px) {
  .app__column {
    width: 1280px;
  }
}

.app__column_blur {
  filter: blur(3px);
}

.app__column_stop {

}






