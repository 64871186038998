.experience {
  display: grid;
}

@media screen and (max-width: 768px) {
  .experience {
    margin: 0 0 120px 0;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content max-content min-content;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .experience {
    margin: 0 0 210px 0;
    grid-template-rows: min-content min-content 400px min-content;
    row-gap: 27px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .experience {
    grid-template-columns: 542px 1fr;
    grid-template-rows: repeat(3, min-content);
    column-gap: 52px;
    row-gap: 35px;
    margin: 0 38px 255px 38px;
  }
}

@media screen and (min-width: 1440px) {
  .experience {
    grid-template-columns: 542px 1fr;
    grid-template-rows: repeat(3, min-content);
    column-gap: 52px;
    row-gap: 35px;
    margin: 0 38px 255px 38px;
  }
}

.experience__title {
  align-self: start;
  justify-self: start;
  font-family: Audrey, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  text-transform: uppercase;
  color: #B68F33; 
}

@media screen and (max-width: 768px) {
  .experience__title {
    grid-area: 1 / 2 / 2 / 3;
    margin: 29px 0 13px 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .experience__title {
    grid-area: 1 / 1 / 2 / 2;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .experience__title {
    grid-area: 1 / 1 / 2 / 3;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1440px) {
  .experience__title {
    grid-area: 1 / 1 / 2 / 3;
    margin-bottom: 32px;
  }
}

.experience__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .experience__image {
    grid-area: 3 / 1 / 4 / 3;
    margin-bottom: 23px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .experience__image {
    grid-area: 3 / 1 / 4 / 2;
    margin-bottom: 13px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .experience__image {
    grid-area: 2 / 1 / 4 / 2;
  }
}

@media screen and (min-width: 1440px) {
  .experience__image {
    grid-area: 2 / 1 / 4 / 2;
  }
}



/*.experience__texts {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  display: grid;
  grid-template-columns: 291px 1fr;
  grid-template-rows: 1fr min-content;
  column-gap: 48px;
  row-gap: 16px;
}*/

/*.experience__texts-column {
  display: grid;
  grid-template-columns: 291px 1fr;
  column-gap: 51px;
  padding-top: 31px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}*/

.experience__texts-main {
  align-self: end;
  justify-self: end;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.9);
}

@media screen and (max-width: 768px) {
  .experience__texts-main {
    grid-area: 2 / 2 / 3 / 3;
    margin: 0 12px 36px 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .experience__texts-main {
    grid-area: 2 / 1 / 3 / 2;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .experience__texts-main {
    grid-area: 2 / 2 / 3 / 3;

  }
}

@media screen and (min-width: 1440px) {
  .experience__texts-main {
    grid-area: 2 / 2 / 3 / 3;

  }
}

.experience__texts-column {
  display: grid;
}

@media screen and (max-width: 768px) {
  .experience__texts-column {
    grid-area: 5 / 1 / 6 / 3;
    margin: 0 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .experience__texts-column {
    grid-area: 4 / 1 / 5 / 2;
    grid-template-columns: 10fr 8fr;
    column-gap: 100px;
    padding-top: 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .experience__texts-column {
    grid-area: 3 / 2 / 4 / 3;
    grid-template-columns: 10fr 9fr;
    column-gap: 48px;
    padding-top: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
}

@media screen and (min-width: 1440px) {
  .experience__texts-column {
    grid-area: 3 / 2 / 4 / 3;
    grid-template-columns: 10fr 9fr;
    column-gap: 48px;
    padding-top: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
}

.experience__texts-additional {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
}

@media screen and (max-width: 768px) {
  .experience__lines {
    grid-area: 1 / 1 / 3 / 2;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 10px;
  }
}

@media screen and (min-width: 768px) {
  .experience__lines {
    display: none;
  }
}

.experience__line_1 {
  width: 1px;
  height: 78%;
  background-color: #B68F33;
  align-self: end;
  margin-left: 12px;
}

.experience__line_2 {
  width: 1px;
  height: 65%;
  background-color: rgba(255, 255, 255, 0.8);
  align-self: start;
  margin-right: 30px;
}

