.clients {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

@media screen and (max-width: 768px) {
  .clients {
    margin: 0 45px 282px 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .clients {
    margin: 0 42px 458px 42px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .clients {
    margin: 0 40px 556px 40px;
  }
}

@media screen and (min-width: 1440px) {
  .clients {
    margin: 0 40px 556px 40px;
  }
}

.clients__title {
  font-family: Audrey, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  color: #B68F33;
  margin-left: auto;
  border-bottom: 1px solid #FFFFFF;
  width: max-content;

}

@media screen and (max-width: 768px) {
  .clients__title {
    font-size: 24px;
    line-height: 29px;  
    text-align: right;
    padding-bottom: 6px;
    margin-bottom: 39px;
    margin-right: 8px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .clients__title {
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    padding-bottom: 8px;
    margin-bottom: 63px;
    margin-right: auto;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .clients__title {
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 72px;
    margin-right: auto;
  }
}

@media screen and (min-width: 1440px) {
  .clients__title {
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 72px;
    margin-right: auto;
  }
}

.clients__logos {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-items: center;
  align-items: center;
  /*display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;*/
}

@media screen and (max-width: 768px) {
  .clients__logos {
    grid-template-columns: repeat(9, 200px);
    grid-template-rows: repeat(4, 1fr);
    grid-auto-rows: 78px;
    margin-bottom: 180px;
    margin-left: 33px;
    padding-right: 200px;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .clients__logos {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(9, 1fr);
    grid-auto-rows: 78px;

    margin-bottom: 80px;
    /*grid-template-columns: repeat(9, 200px);
    grid-template-rows: repeat(4, 1fr);
    grid-auto-rows: 78px;
    margin-bottom: 180px;
    margin-left: 33px;
    padding-right: 45px;
    overflow-y: scroll;*/
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .clients__logos {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-auto-rows: 78px;
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1440px) {
  .clients__logos {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-auto-rows: 78px;
    margin-bottom: 80px;
  }
}

.clients__logos-image {
  object-fit: cover;
  transition: 0.5s;
  justify-self: start;
}



@media screen and (max-width: 768px) {
  .clients__logos-image:nth-child(even) {
    justify-self: end;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .clients__logos-image:nth-child(odd) {
    justify-self: end;
  }
  .clients__logos-image:nth-child(-n + 27):nth-child(odd) {
    justify-self: start;
  }
  .clients__logos-image:nth-child(-n + 27):nth-child(even) {
    justify-self: end;
  }
  .clients__logos-image:nth-child(-n + 18):nth-child(odd) {
    justify-self: end;
  }
  .clients__logos-image:nth-child(-n + 18):nth-child(even) {
    justify-self: start;
  }
  .clients__logos-image:nth-child(-n + 9):nth-child(odd) {
    justify-self: start;
  }
  .clients__logos-image:nth-child(-n + 9):nth-child(even) {
    justify-self: end;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .clients__logos-image:nth-child(even) {
    justify-self: end;
  }
}

@media screen and (min-width: 1440px) {
  .clients__logos-image:nth-child(even) {
    justify-self: end;
  }
}

/*.clients__logos-image:nth-child(n + 6) {
  justify-self: end;
}*/

.clients__logos-image:hover {
  opacity: 0.6;
  transform: scale(0.9, 0.9);
}

.clients__overlay {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .clients__overlay {
    margin-left: none;
    align-items: flex-start;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .clients__overlay {
    margin-left: auto;
    align-items: flex-end;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .clients__overlay {
    margin-left: auto;
    align-items: flex-end;
  }
}

@media screen and (min-width: 1440px) {
  .clients__overlay {
    margin-left: auto;
    align-items: flex-end;
  }
}

.clients__overlay-line {
  width: 134px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.6);
}

@media screen and (max-width: 768px) {
  .clients__overlay-line {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .clients__overlay-line {
    margin-bottom: 13px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .clients__overlay-line {
    margin-bottom: 19px;
  }
}

@media screen and (min-width: 1440px) {
  .clients__overlay-line {
    margin-bottom: 19px;
  }
}

.clients__subtitle {
  font-family: Audrey, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}

@media screen and (max-width: 768px) {
  .clients__subtitle {
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .clients__subtitle {
    font-size: 22px;
    line-height: 26px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .clients__subtitle {
    font-size: 24px;
    line-height: 29px;
  }
}

@media screen and (min-width: 1440px) {
  .clients__subtitle {
    font-size: 24px;
    line-height: 29px;
  }
}

.clients__subtitle-link {
  text-decoration: none;
}