/*.footer__column {
  height: 342px;
  display: grid;
  grid-template-rows: min-content 1fr;
  margin-bottom: 100px;
}
.footer__info {
  align-self: start;
  justify-self: start;
  margin: 20px 0 0 24px;
  width: min-content;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.8);
}

.footer__links {
  align-self: end;
  justify-self: end;
  margin: 0 40px 0 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 26px;
}

.footer__link {
  width: 45px;
}*/



/*.footer__title {
  font-family: Audrey, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 232px;
  line-height: 278px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #B68F33;
  margin-bottom: 22px;
}*/

.footer {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .footer {
    margin: 0 6px;
  }
}

.footer__title {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .footer__title{
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .footer__title{
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .footer__title{
    margin-bottom: 22px;
  }
}

@media screen and (min-width: 1440px) {
  .footer__title{
    margin-bottom: 22px;
  }
}


.footer__links {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .footer__links {
    flex-direction: column;
    align-items: flex-start;
    order: -1;
    margin-bottom: 110px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .footer__links {
    margin: 0 22px 23px 22px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .footer__links {
    margin: 0 55px 32px 55px;
  }
}

@media screen and (min-width: 1440px) {
  .footer__links {
    margin: 0 55px 32px 55px;
  }
}

.footer__link {
  font-family: Audrey;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  color: rgba(182, 143, 51, 0.8);
  padding: 4px;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .footer__link {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .footer__link {
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .footer__link {
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (min-width: 1440px) {
  .footer__link {
    font-size: 12px;
    line-height: 14px;
  }
}

/*.footer__line {
  width: 136px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.4);
}*/

.footer__copyright {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

@media screen and (max-width: 768px) {
  .footer__copyright {
    margin-bottom: 11px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .footer__copyright {
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .footer__copyright {
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 1440px) {
  .footer__copyright {
    margin-bottom: 14px;
  }
}