.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
}

.menu__column {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  visibility: hidden;
}

.menu__column_1 {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  z-index: 3;
}

@media screen and (max-width: 768px) {
  .menu__column_1 {
    top: 17px;
    width: calc(100% - 24px);
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .menu__column_1 {
    top: 43px;
    width: calc(100% - 72px);
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .menu__column_1 {
    top: 50px;
    width: 1200px;
  }
}

@media screen and (min-width: 1440px) {
  .menu__column_1 {
    top: 50px;
    width: 1200px;
  }
}

.menu__column_2 {
  /*z-index: 1;*/
  height: 100%;
}


.menu__background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
}

.menu__links {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

@media screen and (max-width: 768px) {
  .menu__links {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /*grid-template-rows: repeat(4, 18px);
    row-gap: 72px;*/
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .menu__links {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /*grid-template-rows: repeat(4, 27px);
    row-gap: 164px;*/
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .menu__links {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 183px 123px 71px 137px;
    column-gap: 170px;
  }
}

@media screen and (min-width: 1440px) {
  .menu__links {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 183px 123px 71px 137px;
    column-gap: 170px;
  }
}

.menu__link {
  position: relative;
  font-family: Audrey, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  color: #B68F33;
  transition: 1s;
  padding: 2px;
  z-index: 1;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .menu__link  {
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .menu__link  {
    font-size: 28px;
    line-height: 34px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .menu__link  {
    font-size: 28px;
    line-height: 34px;
  }
}

@media screen and (min-width: 1440px) {
  .menu__link  {
    font-size: 28px;
    line-height: 34px;
  }
}

.menu__circle {
  z-index: 0;
  /*grid-area: 1 / 1 / 2 / 2;*/
  animation: logo-background 100s infinite reverse linear;
  position: relative;
}

@media screen and (max-width: 768px) {
  .menu__circle {
    width: 325px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .menu__circle {
    width: 300px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .menu__circle {
    width: 400px;
  }
}

@media screen and (min-width: 1440px) {
  .menu__circle {
    width: 400px;
  }
}

/*.menu__circle_inactive {
  visibility: hidden;
  display: none;
}*/

@media screen and (max-width: 1280px){
  .menu__link_1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  
  .menu__link_2 {
    grid-area: 2 / 1 / 3 / 2;
  }
  
  .menu__link_3 {
    grid-area: 3 / 1 / 4 / 2;
  }
  
  .menu__link_4 {
    grid-area: 4 / 1 / 5 / 2;
  }
}

@media screen and (min-width: 1280px) {
  .menu__link_1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  
  .menu__link_2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  
  .menu__link_3 {
    grid-area: 1 / 3 / 2 / 4;
  }
  
  .menu__link_4 {
    grid-area: 1 / 4 / 2 / 5;
  }
}



.menu__link_inactive {
  color: rgba(182, 143, 51, 0.4);
}

.menu__button-open {
  /*position: absolute;
  top: 61px;
  right: 25px;
  padding: 12px;*/
  grid-area: 1 / 2 / 2 / 3;  
  justify-self: end;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  z-index: 4;
}

.menu__button-open:hover {
  opacity: 0.8;
}

.menu__button-open-point {
  border-radius: 50%;
  background-color: #B68F33;
}

@media screen and (max-width: 768px) {
  .menu__button-open-point {
    width: 8px;
    height: 8px;
    margin-right: 6px;
    margin-bottom: 2px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .menu__button-open-point {
    width: 12px;
    height: 12px;
    margin-right: 11px;
    margin-bottom: 4px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .menu__button-open-point {
    width: 12px;
    height: 12px;
    margin-right: 11px;
    margin-bottom: 4px;
  }
}

@media screen and (min-width: 1440px) {
  .menu__button-open-point {
    width: 12px;
    height: 12px;
    margin-right: 11px;
    margin-bottom: 4px;
  }
}

.menu__button-open-text {
  font-family: Audrey, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .menu__button-open-text {
    font-size: 12px;
    line-height: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .menu__button-open-text {
    font-size: 24px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .menu__button-open-text {
    font-size: 24px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1440px) {
  .menu__button-open-text {
    font-size: 24px;
    line-height: 24px;
  }
}

.menu__button-close {
  /*position: absolute;
  top: 58px;
  right: 50px;*/
  grid-area: 1 / 2 / 2 / 3;
  justify-self: end;
  /*background-color: #B68F33;
  border-radius: 100%;
  box-sizing: border-box;
  display: grid;*/
  transition: 1s;
  margin-right: 14px;
  z-index: 4;
}

@media screen and (max-width: 768px) {
  .menu__logo {
    padding: 6px;
    width: 18px;
    height: 18px;
  }
}

@media screen and (min-width: 768px) {
  .menu__logo {
    width: 56px;
    height: 56px;
  }
}

.menu__logo {
  visibility: visible;
}

@media screen and (max-width: 768px) {
  .menu__logo {
    width: 50px;
    height: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .menu__logo {
    width: 66px;
    height: 66px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .menu__logo {
    width: 90px;
    height: 90px;
  }
}

@media screen and (min-width: 1440px) {
  .menu__logo {
    width: 90px;
    height: 90px;
  }
}

