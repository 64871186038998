.main {
  position: relative;
}

@media screen and (max-width: 768px) {
  .main {
    height: 100vh;
    margin: 0 12px 100px 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main {
    height: 100vh;
    margin: 0 0 210px 0;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main {
    height: 100vh;
    margin: 0 0 300px 0;
  }
}

@media screen and (min-width: 1440px) {
  .main {
    height: 100vh;
    margin: 0 0 300px 0;
  }
}


.main__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: max-content 1fr;
  justify-items: center;
  align-items: center;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .main__title {
    column-gap: 11px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main__title {
    column-gap: 36px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main__title {
    column-gap: 27px;
  }
}

@media screen and (min-width: 1440px) {
  .main__title {
    column-gap: 27px;
  }
}

.main__title-text {
  font-family: Audrey, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  /*line-height: 119px;*/
  text-transform: uppercase;
  transition: 1s;
}

@media screen and (max-width: 768px) {
  .main__title-text {
    font-size: 36px;
    line-height: 36px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main__title-text {
    font-size: 72px;
    line-height: 72px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main__title-text {
    font-size: 98px;
    line-height: 98px;
  }
}

@media screen and (min-width: 1440px) {
  .main__title-text {
    font-size: 98px;
    line-height: 98px;
  }
}

.main__title-text_position {
  position: absolute;
  top: 0;
}

@media screen and (max-width: 768px) {
  .main__title-text_position {
    right: -25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main__title-text_position {
    right: -41px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main__title-text_position {
    right: -93px;
  }
}

@media screen and (min-width: 1440px) {
  .main__title-text_position {
    right: -93px;
  }
}

.main__title-text_image {
  opacity: 0;

  display: grid;
  grid-template-columns: repeat(2, min-content);
  align-items: top;
}

@media screen and (max-width: 768px) {
  .main__title-text_image {
    column-gap: 6px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main__title-text_image {
    column-gap: 10px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main__title-text_image {
    column-gap: 13px;
  }
}

@media screen and (min-width: 1440px) {
  .main__title-text_image {
    column-gap: 13px;
  }
}

.main__title-text_color_gold {
  color: #B68F33;
}

/*.main__title-text-image {

}*/

@media screen and (max-width: 768px) {
  .main__title-text-image {
    margin-top: 5px;
    width: 240px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main__title-text-image {
    margin-top: 11px;
    width: 500px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main__title-text-image {
    margin-top: 14px;
    width: 704px;
  }
}

@media screen and (min-width: 1440px) {
  .main__title-text-image {
    margin-top: 14px;
    width: 704px;
  }
}

.main__title-line {
  justify-self: start;
  background-color: #B68F33;
}

@media screen and (max-width: 768px) {
  .main__title-line {
    width: 72px;
    height: 2px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main__title-line {
    width: 148px;
    height: 4px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main__title-line {
    width: 200px;
    height: 5px;
  }
}

@media screen and (min-width: 1440px) {
  .main__title-line {
    width: 200px;
    height: 5px;
  }
}

.main__title-overlay {
  grid-column-start: 1;
  grid-column-end: 3;
  justify-self: end;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .main__title-overlay {
    height: 36px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main__title-overlay {
    height: 72px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main__title-overlay {
    height: 98px;
  }
}

@media screen and (min-width: 1440px) {
  .main__title-overlay {
    height: 98px;
  }
}

.main__side-text {
  position: absolute;
  top: 336px;
  width: 720px;
  height: 47px;
  font-family: Audrey, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 47px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(182, 143, 51);
  opacity: 0.2;
  transform: rotate(-90deg);
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .main__side-text {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main__side-text {
    left: -250px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main__side-text {
    left: -151px;
  }
}

@media screen and (min-width: 1440px) {
  .main__side-text {
    left: -151px;
  }
}

.main__side-text_color {
  opacity: 0.5;
}

.main__upper-text {
  position: absolute;
  font-family: Audrey, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .main__upper-text {
    bottom: calc(20% + 100px);
    left: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main__upper-text {
    top: 68px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main__upper-text {
    top: 75px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media screen and (min-width: 1440px) {
  .main__upper-text {
    top: 75px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.main__info {
  position: absolute;
  display: grid;
}

@media screen and (max-width: 768px) {
  .main__info {
    bottom: 20%;
    width: 256px;
    grid-template-rows: min-content 1fr;
    row-gap: 6px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main__info {
    bottom: 20%;
    width: 397px;
    grid-template-rows: min-content 1fr;
    row-gap: 17px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main__info {
    bottom: 20%;
    left: 40px;
    grid-template-columns: 342px 342px;
    column-gap: 40px;
    align-items: start;
  }
}

@media screen and (min-width: 1440px) {
  .main__info {
    bottom: 20%;
    left: 40px;
    grid-template-columns: 342px 342px;
    column-gap: 40px;
    align-items: start;
  }
}

.main__info-text {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: normal; 
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .main__info-text {
    font-size: 9px;
    line-height: 11px;
    text-align: start;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main__info-text {
    font-size: 12px;
    line-height: 15px;
    text-align: start;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main__info-text {
    font-size: 12px;
    line-height: 15px;
    text-align: justify; 
  }
}

@media screen and (min-width: 1440px) {
  .main__info-text {
    font-size: 12px;
    line-height: 15px;
    text-align: justify; 
  }
}

.main__info-text_uppercase {
  text-transform: uppercase;
}

/*.main__info-text_position {
  width: min-content;
  justify-self: end;
  text-align: right;
}*/

@keyframes logo-background {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main__logo-background {
  position: absolute;
  width: 735px;
  height: 735px;
  animation: logo-background 180s infinite;
  z-index: -1;
  opacity: 0.1;
}

@media screen and (max-width: 768px) {
  .main__logo-background {
    top: 8px;
    right: -120px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .main__logo-background {
    top: 186px;
    right: -169px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .main__logo-background {
    top: 267px;
    left: 637px;
  }
}

@media screen and (min-width: 1440px) {
  .main__logo-background {
    top: 267px;
    left: 637px;
  }
}

