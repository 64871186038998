.searchbar {
  visibility: visible;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  /*display: flex;
  justify-content: space-between;
  align-items: center;*/
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .searchbar {
    width: calc(100% - 24px);
    height: 20px;
    bottom: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .searchbar {
    width: calc(100% - 72px);
    bottom: 13px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .searchbar {
    width: 1200px;
    height: 76px;
    bottom: 0px;
    padding: 30px 0 ;
  }
}

@media screen and (min-width: 1440px) {
  .searchbar {
    width: 1200px;
    height: 76px;
    bottom: 0px;
    padding: 30px 0;
  }
}

.searchbar__links {
  display: grid;
}

@media screen and (max-width: 768px) {
  .searchbar__links {
    position: absolute;
    grid-template-rows: repeat(4, max-content);
    row-gap: 13px;
    top: -120px;
    right: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .searchbar__links {
    grid-template-columns: repeat(4, max-content);
    column-gap: 17px;
    grid-area: 1 / 1 / 2 / 2;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .searchbar__links {
    grid-template-columns: repeat(4, max-content);
    column-gap: 17px;
    grid-area: 1 / 1 / 2 / 2;
  }
}

@media screen and (min-width: 1440px) {
  .searchbar__links {
    grid-template-columns: repeat(4, max-content);
    column-gap: 17px;
    grid-area: 1 / 1 / 2 / 2;
  }
}

.searchbar__link-image {
  width: 24px;
  height: 24px;
}

.searchbar__input {
  grid-area: 1 / 1 / 2 / 3;
  border: none;
  background-color: inherit;
  border-bottom: 1px solid #B68F33;
  outline: none;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .searchbar__input {
    width: 198px;
    height: 20px;
    padding: 2px 2px;
    font-size: 12px;
    line-height: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .searchbar__input {
    width: 400px;
    height: 57px;
    padding: 14px 10px;
    font-size: 24px;
    line-height: 29px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .searchbar__input {
    width: 482px;
    height: 57px;
    padding: 14px 10px;
    font-size: 24px;
    line-height: 29px;
  }
}

@media screen and (min-width: 1440px) {
  .searchbar__input {
    width: 482px;
    height: 57px;
    padding: 14px 10px;
    font-size: 24px;
    line-height: 29px;
  }
}

.searchbar__buttons {
  display: grid;
  column-gap: 34px;
  align-items: center;
  z-index: 0;
}

@media screen and (max-width: 768px) {
  .searchbar__buttons  {
    grid-area: 1 / 1 / 2 / 2;
    grid-template-columns: repeat(2, max-content) 1fr;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .searchbar__buttons  {
    grid-area: 1 / 2 / 2 / 3;
    grid-template-columns: max-content 126px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .searchbar__buttons  {
    grid-area: 1 / 2 / 2 / 3;
    grid-template-columns: repeat(2, max-content);
  }
}

@media screen and (min-width: 1440px) {
  .searchbar__buttons  {
    grid-area: 1 / 2 / 2 / 3;
    grid-template-columns: repeat(2, max-content);
  }
}

.searchbar__button {
  background-color: inherit;

  /*padding: 0 22px;*/
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: #B68F33;
}

@media screen and (max-width: 768px) {
  .searchbar__button {
    font-size: 12px;
    line-height: 15px;
    border: none;
    padding: 2px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .searchbar__button {
    width: 148px;
    height: 47px;
    border: 2px solid #B68F33;
    border-radius: 20px;
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .searchbar__button {
    width: 148px;
    height: 47px;
    border: 2px solid #B68F33;
    border-radius: 20px;
    font-size: 18px;
    line-height: 22px;
  }
}

@media screen and (min-width: 1440px) {
  .searchbar__button {
    width: 148px;
    height: 47px;
    border: 2px solid #B68F33;
    border-radius: 20px;
    font-size: 18px;
    line-height: 22px;
  }
}

/*.searchbar__button_width {
  
}*/

@media screen and (max-width: 768px) {
  /*.searchbar__button {

  }*/
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .searchbar__button_width {
    width: 126px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .searchbar__button_width {
    width: 126px;
  }
}

@media screen and (min-width: 1440px) {
  .searchbar__button_width {
    width: 126px;
  }
}

.searchbar__button_popup {
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  border-bottom: 1px solid #B68F33;
  
}

/*.searchbar__button_padding_2 {
  padding: 0 28px;
}

.searchbar__button_padding_3 {
  padding: 0 27px;
}*/

.searchbar__button_1 {
  grid-area: 1 / 1 / 2 / 2;
}

.searchbar__button_2 {
  grid-area: 1 / 2 / 2 / 3;
}


.searchbar__button_3 {

  transform: translate(0, 100px);
}

@media screen and (max-width: 768px) {
  .searchbar__button_3 {
    grid-area: 1 / 3 / 2 / 4;
    justify-self: end;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .searchbar__button_3 {
    grid-area: 1 / 2 / 2 / 3;
    justify-self: end;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .searchbar__button_3 {
    grid-area: 1 / 1 / 2 / 2;
  }
}

@media screen and (min-width: 1440px) {
  .searchbar__button_3 {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.searchbar__search {

}

@media screen and (max-width: 768px) {
  .searchbar__search {
    position: absolute;
    height: 100%;
    top: 0;
    right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
  .searchbar__search {
    position: absolute;
    width: 100%;
    top: -60%;
    display: flex;
    justify-content: space-between;
    /*grid-area: 1 / 1 / 2 / 3;*/
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px){
  .searchbar__search {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    column-gap: 148px;
    grid-area: 1 / 1 / 2 / 2;
  }
}

@media screen and (min-width: 1440px) {
  .searchbar__search {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    column-gap: 148px;
    grid-area: 1 / 1 / 2 / 2;
  }
}

.searchbar__search-li {
  display: flex;
  align-items: center;

}

.searchbar__search-text {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.searchbar__search-image {
  width: 14px;
  height: 7px;
  margin-left: 17px;
}

@media screen and (max-width: 768px) {
  .searchbar__popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 4;
  }
}

@media screen and (min-width: 768px){
  .searchbar__popup {
    display: none;
  }
}